<template>
  <div class="approval-page">
    <div class="approval-page__wrapper">
      <div class="contianerx py-6">
        <div
          class="loader d-flex align-center justify-center"
          v-if="isLoadingDetails"
        >
          <v-progress-circular
            color="primary"
            indeterminate
          ></v-progress-circular>
        </div>
        <div v-else>
          <div
            id="pdfContent"
            v-if="generatedReceipt"
            style="text-align: center; padding-left: 40px; padding-right: 40px"
          >
            <img
              :src="require('@/assets/icons/org-logo.png')"
              style="width: 60px; height: auto; margin-top: 20px"
            />
            <p
              style="
                font-family: 'Inter';
                font-style: normal;
                font-weight: 700;
                font-size: 18px;
                line-height: 24px;
                text-align: center;
                color: rgba(25, 40, 61, 0.8);
                margin-top: 24px;
              "
            >
              Receipt from {{ generatedReceipt.orgname }}
            </p>
            <p
              style="
                font-family: 'Inter';
                font-style: normal;
                font-weight: 400;
                font-size: 14px;
                line-height: 24px;
                text-align: center;
                color: rgba(25, 40, 61, 0.8);
                margin-bottom: 28px;
              "
            >
              {{ generatedReceipt.receipt }}
            </p>
            <table
              style="
                width: 100%;
                margin: 0 auto;
                padding-left: 40px;
                padding-right: 40px;
              "
            >
              <tr>
                <td
                  style="
                    text-align: left;
                    padding: 5px;
                    background-color: #f4f5f6;
                    text-transform: uppercase;
                    color: #8f96a1;
                    font-size: 14px;
                    font-weight: 700;
                  "
                >
                  amount paid
                </td>
                <td
                  style="
                    text-align: right;
                    padding: 5px;
                    background-color: #f4f5f6;
                    text-transform: uppercase;
                    font-size: 14px;
                    font-weight: 400;
                  "
                >
                  {{ generatedReceipt.paid | currency(currencyDefault) }}
                </td>
              </tr>
              <tr>
                <td
                  style="
                    text-align: left;
                    padding: 5px;
                    text-transform: uppercase;
                    color: #8f96a1;
                    font-size: 14px;
                    font-weight: 700;
                  "
                >
                  Date Paid
                </td>
                <td
                  style="
                    text-align: right;
                    padding: 5px;
                    font-size: 14px;
                    font-weight: 400;
                  "
                >
                  {{ generatedReceipt.date }}
                </td>
              </tr>
              <tr>
                <td
                  style="
                    text-align: left;
                    padding: 5px;
                    background-color: #f4f5f6;
                    text-transform: uppercase;
                    color: #8f96a1;
                    font-size: 14px;
                    font-weight: 700;
                  "
                >
                  type
                </td>
                <td
                  style="
                    text-align: right;
                    padding: 5px;
                    background-color: #f4f5f6;
                    text-transform: uppercase;
                    font-size: 14px;
                    font-weight: 400;
                  "
                >
                  {{ generatedReceipt.type }}
                </td>
              </tr>
              <tr>
                <td
                  style="
                    text-align: left;
                    padding: 5px;
                    text-transform: uppercase;
                    color: #8f96a1;
                    font-size: 14px;
                    font-weight: 700;
                  "
                >
                  Invoice applied
                </td>
                <td
                  style="
                    text-align: right;
                    padding: 5px;
                    max-width: 150px;
                    white-space: nowrap;
                    overflow: hidden;
                    text-overflow: ellipsis;
                    direction: rtl;
                    text-transform: uppercase;
                    font-size: 14px;
                    font-weight: 400;
                  "
                >
                  {{ generatedReceipt.invoice }}
                </td>
              </tr>
            </table>
            <div
              style="
                display: flex;
                justify-content: center;
                align-items: center;
                margin-top: 12px;
                margin-left: 10px;
                padding: 4px;
                width: 100%;
              "
              v-if="generatedReceipt"
            >
              <p
                style="
                  font-family: 'Inter';
                  font-style: normal;
                  font-weight: 400;
                  font-size: 12px;
                  line-height: 24px;
                  text-align: center;
                  color: #8f96a1;
                "
              >
                If you have any questions, contact <br />
                {{ generatedReceipt.support }}
              </p>
            </div>
          </div>

          <div
            class="d-flex justify-center align-center py-4 mx-10 px-4 w-50"
            v-if="showBtns"
            id="actionBtn"
          >
            <p class="link__text mx-2" @click="printDocument">print</p>
            <p class="link__text mx-2" @click="generatePDF">download</p>
          </div>
        </div>
      </div>
      <div class="contianerx__footer">
        powered by
        <img :src="require('@/assets/icons/newLogo.svg')" alt="logo" />
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import currencyFilter from "@/mixins/currencyFilter.js";
import html2pdf from "html2pdf.js";

//import { mapActions } from "vuex";
export default {
  name: "transactionReceipt",
  data() {
    return {
      isLoadingDetails: true,
      isReceiptLoaded: false,
      showBtns: false,
    };
  },

  mixins: [currencyFilter],
  methods: {
    ...mapActions({ showToast: "ui/showToast" }),

    //
    async handleReceiptGeneration() {
      this.isLoadingDetails = true;
      const req_data = {
        organisationId: this.organizationToken.data.hypn_id,
        receiptId: this.getPaymentId,
      };
      try {
        await this.$store.dispatch(
          "transactions/generatePayableReceipt",
          req_data
        );
        // Set isReceiptLoaded to true after receipt data is loaded
        this.isReceiptLoaded = true;
        this.showBtns = true;
      } catch (error) {
        this.showToast({
          sclass: "error",
          show: true,
          message: "Unable to generate receipt",
          timeout: 3000,
        });
      } finally {
        this.isLoadingDetails = false;
      }
    },

    printDocument() {
      // Open the print window
      window.print();
    },

    generatePDF() {
      // Check if the receipt data is loaded before generating the PDF
      if (!this.isReceiptLoaded) {
        this.showToast({
          sclass: "warning",
          show: true,
          message: "Please wait for receipt data to load",
          timeout: 3000,
        });
        return;
      }

      const element = document.getElementById("pdfContent");
      const options = {
        margin: 10,
        paddingRight: 40,
        paddingLeft: 40,
        filename: `${this.generatedReceipt.orgname} receipt`,
        image: { type: "jpeg", quality: 0.98 },
        html2canvas: { scale: 2 },
        jsPDF: { unit: "mm", format: "a4", orientation: "portrait" },
      };

      html2pdf().from(element).set(options).save();

      this.showToast({
        sclass: "success",
        show: true,
        message: "Receipt has been downloaded",
        timeout: 3000,
      });
    },
  },
  computed: {
    ...mapGetters({
      organizationToken: "organizations/OrganToken",
      generatedReceipt: "transactions/getGeneratedReceipt",
    }),
    getPaymentId() {
      return this.$route.params.paymentId;
    },

    pdfName() {
      return this.generatedReceipt
        ? `${this.generatedReceipt?.orgname} receipt`
        : "receiptPDF";
    },
  },
  mounted() {
    this.handleReceiptGeneration();
  },
};
</script>

<style lang="scss" scoped>
p {
  margin-bottom: 0px;
}

.approval-page {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  &__wrapper {
    .page_title {
      font-family: "Inter";
      font-style: normal;
      font-weight: 700;
      font-size: 24px;
      line-height: 29px;
      color: #19283d;
    }
  }

  .contianerx {
    display: inline-block;
    min-width: 425px;
    text-align: center;
    max-width: 768px;
    width: 90%;
    margin: auto;
    background: #ffffff;
    box-shadow: 0px 4px 16px rgba(204, 188, 252, 0.15);
    border-radius: 8px;

    .title__header {
      font-family: "Inter";
      font-style: normal;
      font-weight: 700;
      font-size: 18px;
      line-height: 24px;
      text-align: center;
      color: rgba(25, 40, 61, 0.8);
    }

    .sub__title {
      font-family: "Inter";
      font-style: normal;
      font-weight: 400;
      font-size: 14px;
      line-height: 24px;
      text-align: center;
      color: rgba(25, 40, 61, 0.8);
    }

    .row__title {
      font-family: "Inter";
      font-style: normal;
      font-weight: 600;
      font-size: 14px;
      line-height: 24px;
      text-transform: uppercase;
      color: #8f96a1;
    }

    .row__value {
      font-family: "Inter";
      font-style: normal;
      font-weight: 400;
      font-size: 16px;
      line-height: 24px;
      color: rgba(25, 40, 61, 0.8);
      padding-right: 8px;
    }

    .text-truncate {
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      direction: rtl;
    }

    .info_text {
      font-family: "Inter";
      font-style: normal;
      font-weight: 400;
      font-size: 12px;
      line-height: 24px;
      text-align: center;
      color: #8f96a1;
    }

    .link__text {
      font-family: "Inter";
      font-style: normal;
      font-weight: 600;
      font-size: 12px;
      line-height: 24px;
      text-decoration-line: underline;
      color: #5b67ba;
      cursor: pointer;
    }

    &__footer {
      margin: 40px 0 5px 0;
      display: flex;
      justify-content: center;
      align-items: center;
      gap: 10px;
      font-size: 14px;
      color: #8f96a1;

      img {
        height: 15px;
      }
    }

    .loader {
      height: 60vh;
    }
  }
}

.action {
  border-radius: 8px;
  background-color: #fff;

  &__header {
    padding: 20px;
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;

    // mobile view
    @media (max-width: 600px) {
      padding: 15px;
    }

    .b {
      display: flex;
      align-items: center;
      gap: 10px;
    }

    .i {
      width: 26px;
      height: 26px;
      object-fit: fit;

      @media (max-width: 600px) {
        width: 20px;
        height: 20px;
      }
    }

    .t {
      color: var(--v-primary-base);
      font-weight: 600;
      font-size: 20px;

      @media (max-width: 600px) {
        font-size: 16px;
      }
    }
  }

  &__content {
    background-color: #f8f7f4;
    padding: 20px 50px;

    @media (max-width: 600px) {
      padding: 20px 20px;
    }

    .top {
      padding: 30px 0px 0px 0px;
      box-shadow: 0px 1px 0px rgba(0, 0, 0, 0.1);

      @media (max-width: 600px) {
        padding: 10px 0px 0px 0px;
      }

      .action-title {
        color: var(--v-primary-base);
        text-transform: uppercase;
        font-weight: 600;
        border-bottom: 5px solid var(--v-primary-base);
        font-size: 16px;
        height: 40px;
        padding: 0px 15px;
        display: inline-block;

        // mobile view
        @media (max-width: 600px) {
          font-size: 14px;
          font-weight: 500;
          border-bottom: 3px solid var(--v-primary-base);
          height: 35px;
          padding: 0px 10px;
        }
      }
    }

    .action-description {
      color: var(--v-primary-base);
      display: block;
      padding: 20px 0px;
      font-size: 16px;
    }

    .a-wrapper {
      background-color: #fff;
      margin: 50px 20px;
      border-radius: 4px;

      // mobile view
      @media (max-width: 600px) {
        padding: 0px;
      }
    }

    .note {
      display: block;
      margin-top: 10px;
      color: #8f96a1;
    }

    .bottom {
      display: flex;
      justify-content: flex-end;
      margin: 20px 20px;
      gap: 20px;

      // mobile view
      @media (max-width: 600px) {
        flex-direction: column;
        gap: 10px;
      }
    }
  }
}

table {
  border-collapse: collapse;
  width: 100%;
}

table,
th,
td {
  border: none;
  /* Remove borders from table, th, and td elements */
}

@media print {
  /* Hide the action buttons when printing */
  #actionBtn {
    display: none !important;
  }
}
</style>
